import React, { Component } from 'react';
import { session } from '../../lib/api';

import illustration from '../../img/undraw_Hello_qnas.svg';

class SignIn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                email: "",
                password: ""
            },
            error: false
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [name]: value
                },
                error: false
            };
        });
    }

    onFormSubmit(event) {
        event.preventDefault();

        session.signin(this.state.form)
            .then(user => {
                this.props.onSignIn(user);
            })
            .catch(error => {
                this.setState({
                    error: true
                });
            });
    }

    render() {
        const errorClass = this.state.error ? "is-danger" : "";

        return (
            <section id="registar" className="section is-medium is-fullscreen">
                <div className="columns is-vcentered">
                    <div className="column is-4 is-offset-1 has-text-centered is-hidden-mobile">
                        <img className="illustration" src={illustration} alt=""/>
                    </div>
                    <div className="column is-5-tablet is-4-desktop is-3-fullhd is-offset-1">
                        <h1 className="title has-text-primary">Entrar</h1>
                        <form name="contact" method="POST" onSubmit={this.onFormSubmit}>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className={"input " + errorClass} name="email" type="email" required placeholder="E-mail" value={this.state.form.email} onChange={this.onFormChange}/>
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className={"input " + errorClass} name="password" type="password" required placeholder="Palavra passe" value={this.state.form.password} onChange={this.onFormChange}/>
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control">
                                    <button type="submit" className="button is-primary">Entrar</button>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

export default SignIn;
